<template>
  <div class="index">
    <header>
     
      <div class="headerTop">
        <div class="container">
          <div class="logo">
            <h1>
              <img src="@/assets/images/logo.png" alt="logo" />
            </h1>
          </div>

          <ul>
            <li class="cur"><router-link to="/">首页</router-link></li>
            <li><router-link to="wisdom">智量百业</router-link></li>
            <li><router-link to="shop">同城好店</router-link></li>
            <li><router-link to="assistant">抖微助手</router-link></li>
            <li><router-link to="flyfishoutlets">飞鱼系统</router-link></li>
            <li><router-link to="commerce">跨境电商</router-link></li>
            <!-- <li><router-link to="contact">联系我们</router-link></li> -->
            <li><router-link to="about">关于我们</router-link></li>
          </ul>
        </div>
      </div>
      <!-- <img src="@/assets/images/banner.jpg" alt="" /> -->
      <img src="@/assets/images/index-banner.jpg" alt="" />
    </header>
    <!-- 同城好店 -->
    <div class="" style="text-align: center">
      <img src="@/assets/images/index-01.jpg" alt="" />
      <img src="@/assets/images/index-02.jpg" alt="" />
      <img src="@/assets/images/index-3.jpg" alt="" />
      <img src="@/assets/images/index-4.jpg" alt="" />
      <img src="@/assets/images/index-5.jpg" alt="" />
      <img src="@/assets/images/index-6.jpg" alt="" />
      <img src="@/assets/images/index-7.jpg" alt="" />
      <img src="@/assets/images/index-8.jpg" alt="" />
    </div>
    <!-- <section class="container pdb100 shop">
      <div class="title">
        <h4>
          <img src="@/assets/images/shop.jpg" alt="同城好店" />
          <span>同城好店</span>
        </h4>
        <p>同城3-5公里精准引流服务</p>
      </div>
      <div class="main">
        <div class="shop-bg">
          <img src="@/assets/images/shop-bg-1.png" alt="" />
        </div>
        <div class="item_circle item_circle1">
          <img src="@/assets/images/shop-1.jpg" alt="工具服务" />
          <p>抖音门店+同城好店</p>
          <p>工具服务</p>
        </div>
        <div class="item_circle item_circle2">
          <img src="@/assets/images/shop-2.jpg" alt="全城引流" />
          <p>精准用户三天裂变</p>
          <p>全城引流</p>
        </div>
        <div class="item_circle item_circle3">
          <img src="@/assets/images/shop-3.jpg" alt="拍摄剪辑" />
          <p>AI高质量短视频</p>
          <p>拍摄剪辑</p>
        </div>
        <div class="item_circle item_circle4">
          <img src="@/assets/images/shop-4.jpg" alt="设计支持" />
          <p>个性化活动海报</p>
          <p>设计支持</p>
        </div>
        <div class="item_circle item_circle5">
          <img src="@/assets/images/shop-5.jpg" alt="分析运营" />
          <p>抖音裂变数据</p>
          <p>分析运营</p>
        </div>
      </div>
    </section> -->
    <!-- 抖微助手 -->
    <!-- <section class="pd100 assistant">
      <div class="container">
        <div class="title">
          <img src="@/assets/images/assistant.jpg" alt="抖微助手" />
          <span>抖微助手</span>
        </div>
        <div class="main">
          <ul>
            <li>
              <p>独创裂变引流系统</p>
              <p>独创裂变引流系统，1变5、5变25指数级裂变</p>
            </li>
            <li>
              <p>分享引流自动锁客</p>
              <p>分享引流自动锁客，各级数据清晰明了</p>
            </li>
            <li>
              <p>搭建私域流量池</p>
              <p>搭建私域流量池，变现涨粉相互协同</p>
            </li>
          </ul>
          <img src="@/assets/images/assistant-1.jpg" alt="" />
        </div>
      </div>
    </section> -->
    <!-- 飞鱼小程序 -->
    <!-- <section class="pd100 container program">
      <div class="title">
        <img src="@/assets/images/program.jpg" alt="飞鱼小程序" />
        <span>飞鱼小程序</span>
      </div>
      <ul class="main">
        <li>
          <img src="@/assets/images/program-1.png" alt="免费开店" />
          <p class="msg">一键免费开店</p>
          <img src="@/assets/images/program-1-1.jpg" alt="" />
          <p>个性化店铺装修</p>
          <p>一键规则自动铺货</p>
        </li>
        <li>
          <img src="@/assets/images/program-2.png" alt="分享赚钱" />
          <p class="msg">一键分享赚钱</p>
          <img src="@/assets/images/program-2-1.jpg" alt="" />
          <p>高毛利商品与</p>
          <p>丰富素材库</p>
        </li>
        <li>
          <img src="@/assets/images/program-3.jpg" alt="提现到账" />
          <p class="msg">一键提现到账</p>
          <img src="@/assets/images/program-3-1.jpg" alt="" />
          <p>销售明细</p>
          <p>一目了然</p>
        </li>
        <li>
          <img src="@/assets/images/program-4.jpg" alt="托管运营" />
          <p class="msg">自动化托管运营</p>
          <img src="@/assets/images/program-4-1.jpg" alt="" />
          <p>超低成本卖货与</p>
          <p>裂变涨粉并行</p>
        </li>
        <li>
          <img src="@/assets/images/program-5.png" alt="多种经营模式" />
          <p class="msg">多种经营模式</p>
          <img src="@/assets/images/program-5-1.jpg" alt="" />
          <p>灵活支持多人</p>
          <p>合伙经营</p>
        </li>
      </ul>
    </section> -->
    <!-- 社群自动化运营工具 -->
    <!-- <section class="pd100 container tool">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>社群自动化运营工具</span>
      </div>
      <ul class="main">
        <li>
          <img src="@/assets/images/tool-1.jpg" alt="" />
          <h4>活动及商品自动更新</h4>
          <p>店铺活动及商品自动更新</p>
          <p>个性化推荐触达用户</p>
        </li>
        <li>
          <img src="@/assets/images/tool-2.jpg" alt="" />
          <h4>活动及商品自动更新</h4>
          <p>店铺活动及商品自动更新</p>
          <p>个性化推荐触达用户</p>
        </li>
        <li>
          <img src="@/assets/images/tool-3.jpg" alt="" />
          <h4>活动及商品自动更新</h4>
          <p>店铺活动及商品自动更新</p>
          <p>个性化推荐触达用户</p>
        </li>
      </ul>
    </section> -->
    <!-- 精准引流服务 -->
    <!-- <section class="pd100 container serve">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>精准引流服务</span>
      </div>
      <ul class="main">
        <li>
          <img src="@/assets/images/serve-1.jpg" alt="" />
          <p>AI短视频制作</p>
        </li>
        <li>
          <img src="@/assets/images/serve-2.jpg" alt="" />
          <p>短视频拍摄</p>
        </li>
        <li>
          <img src="@/assets/images/serve-3.jpg" alt="" />
          <p>IP打造</p>
        </li>
        <li>
          <img src="@/assets/images/serve-4.jpg" alt="" />
          <p>品牌推广</p>
        </li>
      </ul>
    </section> -->
    <!-- 合作品牌  -->
    <!-- <section class="pd100 container partner">
      <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>部分合作品牌</span>
      </div>
      <img style="" src="@/assets/images/index-1.jpg" alt="" />
      <img src="@/assets/images/index-2.jpg" alt="" /> -->
    <!-- <div class="item-title">
        <img src="@/assets/images/title-icon.jpg" alt="" />
        <span>部分合作品牌</span>
      </div>
      <img src="@/assets/images/partner.jpg" alt="" /> -->
    <!-- <ul class="main">
        <li>
          <img src="@/assets/images/serve-logo-1.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-2.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-3.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-4.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-5.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-7.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-9.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-11.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-12.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-13.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-14.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-15.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-16.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-17.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-18.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-19.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-20.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-21.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-22.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-23.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-24.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-25.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-26.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-27.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-28.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-29.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-30.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-31.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-32.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-33.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-34.jpg" alt="" />
        </li>
        <li>
          <img src="@/assets/images/serve-logo-35.jpg" alt="" />
        </li>
      </ul> -->
    <!-- </section> -->
    <div>
        <ul class="menu1">
          <li>
            一级菜单1
            <ul class="menu2" id="first">
              <li><router-link to="shop">同城好店</router-link></li>
              <li>二级菜单1</li>
              <li>二级菜单1</li>
              <li>二级菜单1</li>
            </ul>
          </li>
          <li>
            一级菜单2
            <ul class="menu2" id="second">
              <li>二级菜单2</li>
              <li>二级菜单2</li>
              <li>二级菜单2</li>
              <li>二级菜单2</li>
            </ul>
          </li>
          <li>
            一级菜单3
            <ul class="menu2" id="third">
              <li>二级菜单3</li>
              <li>二级菜单3</li>
              <li>二级菜单3</li>
              <li>二级菜单3</li>
            </ul>
          </li>
        </ul>
      </div>
    <!-- 业务合作 -->
    <section class="pd60 contact">
      <div class="container">
        <div class="item-title">
          <img src="@/assets/images/title-icon.jpg" alt="" />
          <span>业务合作</span>
        </div>
      </div>
      <div class="main">
        <div class="container">
          <img src="@/assets/images/contact-2.png" alt="" />
          <p>
            请将合作资料发送至邮箱：1487986688@qq.com 或者加微信：13410419943
          </p>
        </div>
      </div>
    </section>
    <footer>
      <div class="main container pd60">
        <div class="item">
          <h4>关于我们</h4>
          <p>公司介绍</p>
          <p>业务合作</p>
        </div>
        <div class="item">
          <h4>联系我们</h4>
          <p>邮箱：1487986688@qq.com</p>
          <p>电话：13410419943，周一至周日 9:30 - 22:30</p>
          <!-- <p>地址：深圳市福田区车公庙泰然八路安华工业区6栋613</p> -->
        </div>
        <div class="wx">
          <div class="wx-item">
            <img src="@/assets/images/wx-1.jpg" alt="" />
            <p>扫码关注公众号</p>
          </div>
          <div class="wx-item">
            <img src="@/assets/images/wx-2.jpg" alt="" />
            <p>业务合作</p>
          </div>
        </div>
      </div>
      <div class="copyright container pd60">
        <p>©2019-2024 深圳漠阳风科技有限公司 版权所有</p>
        <a href="http://beian.miit.gov.cn/">粤ICP备19157531号-2</a>
      </div>
    </footer>
  </div>
</template>

<script>
import "@/assets/css/base.css";
import "@/assets/css/style.css";
export default {};
</script>
<style lang="less">
li {
  list-style: none;
  padding: 5px;
}

/*设置一级li的样式*/

.menu1 > li {
  float: left;
  position: relative;
  background-color: gray;
  margin-left: 10px;
}

/*设置二级菜单默认隐藏*/

.menu1 > li > ul {
  display: none;
}

/*设置二级菜单的样式和位置*/

.menu2 {
  position: absolute;
  width: 100px;
  left: -45px;
  top: 35px;
}

.menu2 li {
  width: 100px;
  background-color: red;
  margin: 5px;
}

/*设置鼠标滑过的时候显示二级菜单*/

.menu1 > li:nth-child(1):hover #first {
  display: block;
}

.menu1 > li:nth-child(2):hover #second {
  display: block;
}

.menu1 > li:nth-child(3):hover #third {
  display: block;
}

.shop .main {
  position: relative;
}
.shop .main .shop-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 512px;
  height: 512px;
  background: url(../assets/images/shop-bg.jpg) no-repeat;
  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 180px;
    height: 363px;
  }
}
</style>
