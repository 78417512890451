var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index"},[_c('header',[_c('div',{staticClass:"headerTop"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('ul',[_c('li',{staticClass:"cur"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("首页")])],1),_c('li',[_c('router-link',{attrs:{"to":"wisdom"}},[_vm._v("智量百业")])],1),_c('li',[_c('router-link',{attrs:{"to":"shop"}},[_vm._v("同城好店")])],1),_c('li',[_c('router-link',{attrs:{"to":"assistant"}},[_vm._v("抖微助手")])],1),_c('li',[_c('router-link',{attrs:{"to":"flyfishoutlets"}},[_vm._v("飞鱼系统")])],1),_c('li',[_c('router-link',{attrs:{"to":"commerce"}},[_vm._v("跨境电商")])],1),_c('li',[_c('router-link',{attrs:{"to":"about"}},[_vm._v("关于我们")])],1)])])]),_c('img',{attrs:{"src":require("@/assets/images/index-banner.jpg"),"alt":""}})]),_vm._m(1),_c('div',[_c('ul',{staticClass:"menu1"},[_c('li',[_vm._v(" 一级菜单1 "),_c('ul',{staticClass:"menu2",attrs:{"id":"first"}},[_c('li',[_c('router-link',{attrs:{"to":"shop"}},[_vm._v("同城好店")])],1),_c('li',[_vm._v("二级菜单1")]),_c('li',[_vm._v("二级菜单1")]),_c('li',[_vm._v("二级菜单1")])])]),_vm._m(2),_vm._m(3)])]),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('h1',[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":"logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/images/index-01.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/index-02.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/index-3.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/index-4.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/index-5.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/index-6.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/index-7.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("@/assets/images/index-8.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v(" 一级菜单2 "),_c('ul',{staticClass:"menu2",attrs:{"id":"second"}},[_c('li',[_vm._v("二级菜单2")]),_c('li',[_vm._v("二级菜单2")]),_c('li',[_vm._v("二级菜单2")]),_c('li',[_vm._v("二级菜单2")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_vm._v(" 一级菜单3 "),_c('ul',{staticClass:"menu2",attrs:{"id":"third"}},[_c('li',[_vm._v("二级菜单3")]),_c('li',[_vm._v("二级菜单3")]),_c('li',[_vm._v("二级菜单3")]),_c('li',[_vm._v("二级菜单3")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pd60 contact"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"item-title"},[_c('img',{attrs:{"src":require("@/assets/images/title-icon.jpg"),"alt":""}}),_c('span',[_vm._v("业务合作")])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"container"},[_c('img',{attrs:{"src":require("@/assets/images/contact-2.png"),"alt":""}}),_c('p',[_vm._v(" 请将合作资料发送至邮箱：1487986688@qq.com 或者加微信：13410419943 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"main container pd60"},[_c('div',{staticClass:"item"},[_c('h4',[_vm._v("关于我们")]),_c('p',[_vm._v("公司介绍")]),_c('p',[_vm._v("业务合作")])]),_c('div',{staticClass:"item"},[_c('h4',[_vm._v("联系我们")]),_c('p',[_vm._v("邮箱：1487986688@qq.com")]),_c('p',[_vm._v("电话：13410419943，周一至周日 9:30 - 22:30")])]),_c('div',{staticClass:"wx"},[_c('div',{staticClass:"wx-item"},[_c('img',{attrs:{"src":require("@/assets/images/wx-1.jpg"),"alt":""}}),_c('p',[_vm._v("扫码关注公众号")])]),_c('div',{staticClass:"wx-item"},[_c('img',{attrs:{"src":require("@/assets/images/wx-2.jpg"),"alt":""}}),_c('p',[_vm._v("业务合作")])])])]),_c('div',{staticClass:"copyright container pd60"},[_c('p',[_vm._v("©2019-2024 深圳漠阳风科技有限公司 版权所有")]),_c('a',{attrs:{"href":"http://beian.miit.gov.cn/"}},[_vm._v("粤ICP备19157531号-2")])])])
}]

export { render, staticRenderFns }